import React, { useState, useEffect, useContext } from "react";
import { Link , Navigate, useNavigate, useLocation, useParams } from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import Alert from '../../components/Alerts/Alert';
import FeedBackAlert from '../../components/Alerts/SuccessAlert';

import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import SizeAndMeLogo from '../../assets/svg/size_text_logo';
import queryString from 'query-string'

export default function Login() {
  const { verifyKey } = useParams();
  console.log(verifyKey)
  const { t, i18n } = useTranslation();
 
  const navigate = useNavigate();
/*   const location = useLocation();
  const from = location.state?.from?.pathname || "/"; */

  const [showAlert, setShowAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorText, setErrorText] = useState('')
  const [error, setError] = useState(true);
  const [cookies, setCookie] = useCookies(['name']);
  const [disabled, setDisabled] = useState(false)
  const [email, setEmail] = useState('')
  const [condition, setCondition] = useState(null)
 

    
  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.expired) setCondition('expired')
    else if (parsed.error) setCondition('error')
    else if (parsed.condition === "verified") {
      setCondition('verified')
    }
   
    else if (parsed.condition === "requestagain") {
      setCondition('requestagain')
    }
    else if (parsed.condition === "email1") {
      setCondition('email1')
    }
    else if (parsed.condition === "email2") {
      setCondition('email2')
    }
    else if (parsed.condition === "password1") {
      setCondition('password1')
    }
   
    if (parsed.email) setEmail(parsed.email)
    
    
}, []);


  const onSubmit = async (values) => {
    let token =''
    setError(true)
    if (values.password !== values.verifyPassword) {
      setErrorText(t('passwordmustmatch'))
      setShowAlert(true)
      return
                    
    }
    else if (values.password.length < 7) {
      setErrorText(t('passwordmustmatch1'))
      setShowAlert(true)
      return
    }

    
  
    try {  
      const response = await axios.post(`/api/verify/${verifyKey}`, values)
      const cleanResponse = response.data;
      if (cleanResponse === "Success") {
     
        setErrorText(t('passwordsuccess'))
        setShowSuccess(true)
        setDisabled(true)
        return
      } else {
        setErrorText(t('passwordfail'))
        setShowAlert(true)
        return

      }
  
     
    
  } catch (err) {
    setError(true)
    if (!err?.response) {
      setErrorText(t('logerr1'))
    } else if (err.response?.status === 400) {
      setErrorText(t('logerr2'))
    } else if (err.response?.status === 401) {
      setErrorText(t('logerr3'))
    } else {
      setErrorText(t('logerr4'))
    }
    setShowAlert(true)
    
  
  }
}

  return (
    <>
      <div className="container mx-auto px-4 h-full min-h-screen flexEvenly" >
        <div className="flex content-center items-center justify-center h-full">
       
          <div className="w-full  px-4 ">
      
            <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg  border-0 flex-row" style={{display: "flex", flexDirection: "row"}}>
     
              <div className="w-full ">
              {condition === "requestagain" ? 
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-8 expireddescription">
             <h3 id="headerExpired">{t('link__requestagain')}</h3>
               <p id="textExpired">{t('link__error__1')}</p>
          
              </div> : null}
              {condition === "error" ? 
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-8 expireddescription">
             <h3 id="headerExpired">{t('link__error')}</h3>
               <p id="textExpired">{t('link__error__1')}</p>
          
              </div> : null}
              {condition === "verified" ? 
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-8 expireddescription">
             <h3 id="headerExpired">{t('link__verified')}</h3>
               <p id="textExpired">{t('link__expired__4')}</p>
          
              </div> : null}
            
              {condition === "email1" ? 
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-8 expireddescription">
             <h3 id="headerExpired">{t('link__expired__5', {email: email})}</h3>
               <p id="textExpired">{t('link__expired__4')}</p>
          
              </div> : null}
              {condition === "email2" ? 
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-8 expireddescription">
             <h3 id="headerExpired">{t('link__expired__7', {email: email})}</h3>
               <p id="textExpired">{t('link__expired__4')}</p>
          
              </div> : null}
              {condition === "password1" ? 
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-8 expireddescription">
             <h3 id="headerExpired">{t('link__expired__3')}</h3>
               <p id="textExpired">{t('link__expired__4')}</p>
          
              </div> : null}
              {condition === "expired" ? 
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-8 expireddescription">
             <h3 id="headerExpired">{t('link__expired__1')}</h3>
               <p id="textExpired">{t('link__expired__2')}</p>
          
              </div> :  null}
              {condition === "" ?  <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-8 expireddescription">
             <h3 id="headerExpired">{t('link__notfound')}</h3>
               
          
              </div> : null}
              
            </div>
          {/*   <div className="w-full lg:w-6/12 px-4">
            <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/sizeandmelogo.svg" width="200px" id='canvasloginLogo' alt="sizeandmelogo" /> */}
           {/*  <canvas id="gradient-canvaslogin"> </canvas> */}
          {/*   </div> */}
            
        
            </div>
            
          </div>
          
         
        </div>
       <div className="descriptionBottom">
        <span className="textDescriptionWhite">{t('text_1')} <a href="mailto: info@sizeandme.com" style={{textDecoration: "underline"}}>{t('contactus')}</a></span>
        <SizeAndMeLogo />
        <p className="textDescriptionGray">{t('text_2')}</p>
        <div className="descriptionBottom__socialmedia">
            <a href="https://twitter.com/sizeandme" target="_blank" rel="noreferrer"  className="descriptionBottom__socialmedia--button">
              Twitter
            </a>
            <a href="https://instagram.com/sizeandme" target="_blank" rel="noreferrer"  className="descriptionBottom__socialmedia--button">
              Instagram
            </a>
            <a href="https://www.linkedin.com/company/sizeandme/" target="_blank" rel="noreferrer"  className="descriptionBottom__socialmedia--button">
              Linkedin
            </a>
        </div>
       </div>
       
      {/*   <canvas id="gradient-canvaslogin"></canvas> */}
      </div>
      
      {showAlert === true ? <Alert  text={errorText} close={setShowAlert} /> : "" } 
      {showSuccess === true ? <FeedBackAlert  setShowSuccess={setShowSuccess}  text={errorText}/> : null}
    </>
  );
}
