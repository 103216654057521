import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { BrowserRouter, Route, Routes , Outlet, Navigate} from "react-router-dom";

import { CookiesProvider } from 'react-cookie';

import { useTranslation } from 'react-i18next'
import Auth from "./layouts/Auth.js";
import queryString from 'query-string';





const SomeRoutes = () => {
    const { t, i18n } = useTranslation()



   useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.language === "tr") i18n.changeLanguage("tr")
    else i18n.changeLanguage("en")

   }, [])

    return (
        <CookiesProvider>
            {/* add routes with layouts */}
         <Routes>
           
        

            <Route path="/*" element={<Auth  /> }/>
              
      
            
          
       
         </Routes>
        
       </CookiesProvider>
    );
};

export default SomeRoutes;




